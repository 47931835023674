<template>
  <div class="box" :style="bg">
    <nav-router :routerList="useRouterList">
      <img :src="icon" alt="" />
    </nav-router>
    <div class="content">
      <div class="left-column">
        <div class="left-row" v-if="leftList[0] != null">
          <div class="avatar">
            <img :src="`${OSS_URL}/${leftList[0].relativePath}/${leftList[0].filename}`" alt="" />
          </div>
          <div class="info">
            <div class="info-title">
              <div class="info-title-name">
                {{ leftList[0].name }}/
                <span class="info-title-name-position">
                  {{ leftList[0].post }}
                </span>
              </div>
              <img src="@/assets/images/interactive/vote-1.png" alt="" />
            </div>

            <div class="info-number">
              <span>获得票数</span>
              <span>{{ leftList[0].voteNum || 0 }}票</span>
            </div>
            <div class="info-progress">
              <el-progress :text-inside="true" :stroke-width="20" :percentage="
                  (leftList[0].voteNum / leftList[0].totalNum)
                    | formatToFixed(2)
                " status="exception"></el-progress>
            </div>
          </div>
        </div>
        <div class="left-row" v-if="leftList[1] != null">
          <div class="avatar">
            <img :src="`${OSS_URL}/${leftList[1].relativePath}/${leftList[1].filename}`" alt="" />
          </div>
          <div class="info">
            <div class="info-title">
              <div class="info-title-name">
                {{ leftList[1].name }}/
                <span class="info-title-name-position">
                  {{ leftList[1].post }}
                </span>
              </div>
              <img src="@/assets/images/interactive/vote-2.png" alt="" />
            </div>

            <div class="info-number">
              <span>获得票数</span>
              <span>{{ leftList[1].voteNum || 0 }}票</span>
            </div>
            <div class="info-progress">
              <el-progress :text-inside="true" :stroke-width="20" :percentage="
                  (leftList[1].voteNum / leftList[1].totalNum)
                    | formatToFixed(2)
                " status="exception"></el-progress>
            </div>
          </div>
        </div>
        <div class="left-row" v-if="leftList[2] != null">
          <div class="avatar">
            <img :src="`${OSS_URL}/${leftList[2].relativePath}/${leftList[2].filename}`" alt="" />
          </div>
          <div class="info">
            <div class="info-title">
              <div class="info-title-name">
                {{ leftList[2].name }}/
                <span class="info-title-name-position">
                  {{ leftList[2].post }}
                </span>
              </div>
              <img src="@/assets/images/interactive/vote-3.png" alt="" />
            </div>

            <div class="info-number">
              <span>获得票数</span>
              <span>{{ leftList[2].voteNum || 0 }}票</span>
            </div>
            <div class="info-progress">
              <el-progress :text-inside="true" :stroke-width="20" :percentage="
                  (leftList[2].voteNum / leftList[2].totalNum)
                    | formatToFixed(2)
                " status="exception"></el-progress>
            </div>
          </div>
        </div>
      </div>
      <div class="right-column">
        <div class="right-column-container">
          <div class="left-row" v-for="(item, index) in rightList" :key="item.id">
            <div class="avatar">
              <img :src="`${OSS_URL}/${item.relativePath}/${item.filename}`" alt="" />
            </div>
            <div class="info">
              <div class="info-title">
                <div class="info-title-name">
                  {{ item.name }}/
                  <span class="info-title-name-position">
                    {{ item.post }}
                  </span>
                </div>
                <div class="rank-number">
                  <span> {{ index + 4 }}</span>
                </div>
              </div>

              <div class="info-number">
                <span>获得票数</span>
                <span>{{ item.voteNum || 0 }}票</span>
              </div>
              <div class="info-progress">
                <el-progress :text-inside="true" :stroke-width="20" :percentage="(item.voteNum / item.totalNum) | formatToFixed" status="exception"></el-progress>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <menu-pop :activeItem="3"></menu-pop>
    <back :pageDepth="4"></back>
  </div>
</template>

<script>
import menuPop from '@/components/menuPop'
import back from '@/components/back'
import navRouter from '@/components/navRouter'
const redIcon = require('@/assets/images/dangHistory/home.png')
const blueIcon = require('@/assets/images/dangHistory/home-blue.png')
import { mapGetters } from 'vuex'
import { API_MODULE } from '../../request/api'
export default {
  name: 'VoteResult',
  components: {
    menuPop,
    navRouter,
    back
  },
  data() {
    return {
      icon: redIcon,
      bg: {
        backgroundColor: '#E03838'
      },
      bgRed: {
        backgroundColor: '#E03838'
      },
      bgBlue: {
        backgroundColor: '#2070DB'
      },
      leftList: [],
      rightList: []
    }
  },
  methods: {
    getData() {
      this.GET(API_MODULE.GET_VOTE_RESULT, { id: this.$route.query.id })
        .then((res) => {
          this.leftList = res.data.slice(0, 3)
          this.rightList = res.data.slice(3)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  },
  computed: {
    ...mapGetters('skin', ['useSubject', 'useRouterList'])
  },
  created() {
    if (this.useSubject === 'red') {
      this.icon = redIcon
      this.bg = this.bgRed
    } else {
      this.icon = blueIcon
      this.bg = this.bgBlue
    }
    this.getData()
  },
  watch: {
    useSubject(val) {
      if (val === 'red') {
        this.icon = redIcon
        this.bg = this.bgRed
      } else {
        this.icon = blueIcon
        this.bg = this.bgBlue
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/themes/_handle.scss';

.box {
  min-height: 13.6rem;
  background-size: cover;
  background-repeat: no-repeat;

  .content {
    display: flex;
    position: relative;

    padding-bottom: 0.3rem;
    .left-column {
      width: 40%;
      padding-right: 5%;
      display: flex;
      flex-direction: column;
      margin-left: 5%;
      .left-row {
        display: flex;
        margin-top: 0.6rem;
        .avatar {
          width: 2.8rem;
          height: 3.3rem;
          border: 0.1rem solid #ffffff;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .info {
          flex-basis: 80%;
          margin-left: 0.4rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          &-title {
            display: flex;
            justify-content: space-between;
            padding-bottom: 1.2rem;

            &-name {
              font-size: 0.48rem;
              color: #ffffff;

              &-position {
                font-size: 0.36rem;
              }
            }
          }

          &-number {
            display: flex;
            justify-content: space-between;
            font-size: 0.3rem;
            color: #ffffff;
          }

          &-progress ::v-deep .el-progress-bar__inner {
            @include background-color('background-progress');
          }
        }
      }
    }
    .right-column {
      position: absolute;
      right: 10%;
      top: 0.6rem;
      width: 40%;
      padding-right: 5%;
      padding-bottom: 0.2rem;
      overflow: auto;
      max-height: calc(100% - 0.8rem);

      .right-column-container {
        display: flex;
        flex-direction: column;

        .left-row {
          display: flex;
          margin-top: 0.6rem;
          margin-left: 1.2rem;
          &:first-child {
            margin-top: 0;
          }
          .avatar {
            width: 1.6rem;
            height: 1.9rem;
            border: 0.1rem solid #ffffff;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .info {
            flex-basis: 80%;
            margin-left: 0.45rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            &-title {
              display: flex;
              justify-content: space-between;
              padding-bottom: 0.44rem;

              &-name {
                font-size: 0.4rem;
                color: #ffffff;
                flex: 9;

                &-position {
                  font-size: 0.36rem;
                }
              }

              &-btn {
                width: 0.8rem;
                height: 0.8rem;
                @include background_color('background-high');
                color: #ffffff;
                font-size: 48px;
                border-radius: 100%;
              }

              .rank-number {
                width: 0.7rem;
                height: 100%;
                border-radius: 50%;
                @include background_color('background-normal');
                font-size: 0.6rem;
                color: #ffffff;
              }
            }

            &-number {
              display: flex;
              justify-content: space-between;
              font-size: 0.3rem;
              color: #ffffff;
            }
          }
        }
      }
    }

    .right-column::-webkit-scrollbar {
      width: 0 !important;
    }
  }
}
</style>